exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-complete-tsx": () => import("./../../../src/pages/complete.tsx" /* webpackChunkName: "component---src-pages-complete-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-image-upload-form-tsx": () => import("./../../../src/pages/image-upload-form.tsx" /* webpackChunkName: "component---src-pages-image-upload-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-input-form-tsx": () => import("./../../../src/pages/input-form.tsx" /* webpackChunkName: "component---src-pages-input-form-tsx" */),
  "component---src-pages-upload-form-tsx": () => import("./../../../src/pages/upload-form.tsx" /* webpackChunkName: "component---src-pages-upload-form-tsx" */),
  "component---src-pages-warranty-details-tsx": () => import("./../../../src/pages/warranty-details.tsx" /* webpackChunkName: "component---src-pages-warranty-details-tsx" */)
}

